.footer {
  background-color: var(--color-primary-bg);
  color: var(--color-primary-text);
  
  .menu-footer {
    font-size: 16px;
    a {
      color: var(--color-primary-text);
    }
  }
}

.grid-footer {
  .grid-view-more-button {
    text-transform: uppercase;
    font-size: 18px;
    font-weight: 500;
    color: var(--color-primary-bg);
    font-family: $font-family-base;
  }
}
.grid-header {
  h2 {
    text-transform: uppercase;
    font-size: 18px;
    font-weight: 600;
    color: var(--color-base-text);
    font-family: $font-family-heading;
  }
}

.bottom {
  background-color: var(--color-primary-bg-2);
  color: var(--color-primary-text);
}

.page.page-home {
  .card-services {
    padding: 25px;
    background-color: var(--color-base-bg-3);
  }
}


// Fade in effect for smoother page load
#test {
  animation: fadein 0.05s;
  -moz-animation: fadein 0.05s; /* Firefox */
  -webkit-animation: fadein 0.05s; /* Safari and Chrome */
  -o-animation: fadein 0.05s; /* Opera */
}
@keyframes fadein {
  from {
      opacity:0;
  }
  to {
      opacity:1;
  }
}
@-moz-keyframes fadein { /* Firefox */
  from {
      opacity:0;
  }
  to {
      opacity:1;
  }
}
@-webkit-keyframes fadein { /* Safari and Chrome */
  from {
      opacity:0;
  }
  to {
      opacity:1;
  }
}
@-o-keyframes fadein { /* Opera */
  from {
      opacity:0;
  }
  to {
      opacity: 1;
  }
}
