// Lightmode colors
$primary-bg: #322E4A;
$primary-bg-2: #322e4a;
$primary-bg-3: #6c757d;
$primary-bg-text: #fdfcf9;
$base-bg: #ffffff;
$base-bg-2: #f8f9ff;
$base-bg-3: #eef2f9;
$base-text: #111827;
$base-text-2: #4b5563;
$logo-text:  #2b3af7;
$menu-text:  inherit;

// Darkmode colors
$primary-bg-dark: #6c757d;
$primary-bg-2-dark: #2d4156;
$primary-bg-3-dark: #8215cb;
$primary-bg-text-dark: #fdfcf9;
$base-bg-dark: #0f0f10;
$base-bg-2-dark: #1a191c;
$base-bg-3-dark: #222124;
$base-text-dark: #F4F4F5;
$base-text-2-dark: #D1D5DB;
$logo-text-dark: #2b3af7;
$menu-text-dark:  #F4F4F5;

$zs-colors-light: (
    "primary-bg": $primary-bg,
    "primary-bg-2": $primary-bg-2,
    "primary-bg-3": $primary-bg-3,
    "primary-text": $primary-bg-text,
    "base-bg": $base-bg,
    "base-bg-2": $base-bg-2,
    "base-bg-3": $base-bg-3,
    "base-text": $base-text,
    "base-text-2": $base-text-2,
    "logo-text": $logo-text,
    "menu-text": $menu-text
);

$zs-colors-dark: (
    "primary-bg": $primary-bg-dark,
    "primary-bg-2": $primary-bg-2-dark,
    "primary-bg-3": $primary-bg-3-dark,
    "primary-text": $primary-bg-text-dark,
    "base-bg": $base-bg-dark,
    "base-bg-2": $base-bg-2-dark,
    "base-bg-3": $base-bg-3-dark,
    "base-text": $base-text-dark,
    "base-text-2": $base-text-2-dark,
    "logo-text": $logo-text-dark,
    "menu-text": $menu-text-dark
);

$zs-colors-light-css-vars: ();
@each $color, $value in $zs-colors-light {
  $zs-colors-light-css-vars: map-merge(
    $zs-colors-light-css-vars,
    (#{$color}: var(--color-#{$color}))
  )
}

$font-family-heading: Lato, -apple-system, serif;
$font-family-base: 'Source Sans 3', -apple-system, "Helvetica Neue", Arial, sans-serif; 
$font-family-monospace: 'Source Code Pro', SFMono-Regular, Menlo, Monaco, Consolas, "Courier New", monospace;
$font-family-logo: Lato, -apple-system, "Helvetica Neue", Arial, sans-serif;

:root{  
  @each $color, $value in $zs-colors-light {
    --color-#{$color}: #{$value};
  }
}

html[data-mode='dark'] {
  @each $color, $value in $zs-colors-dark {
      --color-#{$color}: #{$value};
  }
}

@import 'style';
