#solution-hero-image > img {
  
}

.img-blur{
  position: relative;
  display: inline-block;
}
.img-blur:after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  box-shadow: inset 0 0 10px 10px #ffffff;
}

.card-img-top {
  width: 100%;
  object-fit: cover;
  aspect-ratio: 16 / 9;
}
