// Bootstrap "5.3.2
// 1. Include functions first (so you can manipulate colors, SVGs, calc, etc)
@import "bootstrap/functions";

// 2. Include our variable overrides here
@import "../theme/variables";

// 3. Include main bootstrap variables and functions
@import "bootstrap/variables";
@import "bootstrap/variables-dark";
@import "bootstrap/maps";
@import "bootstrap/mixins";
@import "bootstrap/utilities";

// 4. Bootstrap Layout & components
@import "bootstrap/root";
@import "bootstrap/reboot";
@import "bootstrap/type";
@import "bootstrap/images";
@import "bootstrap/containers";
@import "bootstrap/grid";
@import "bootstrap/tables";
@import "bootstrap/forms";
@import "bootstrap/buttons";
// @import "bootstrap/transitions";
// @import "bootstrap/dropdown";
// @import "bootstrap/button-group";
// @import "bootstrap/nav";
// @import "bootstrap/navbar";
// @import "bootstrap/card";
// @import "bootstrap/accordion";
// @import "bootstrap/breadcrumb";
// @import "bootstrap/pagination";
// @import "bootstrap/badge";
// @import "bootstrap/alert";
// @import "bootstrap/progress";
@import "bootstrap/list-group";
// @import "bootstrap/close";
// @import "bootstrap/toasts";
// @import "bootstrap/modal";
// @import "bootstrap/tooltip";
// @import "bootstrap/popover";
// @import "bootstrap/carousel";
// @import "bootstrap/spinners";
// @import "bootstrap/offcanvas";
// @import "bootstrap/placeholders";

// Helpers
@import "bootstrap/helpers";

// 7. Include utilties overrides
@import "utilities";

// Utilities
@import "bootstrap/utilities/api";



// Zerostatic Core SCSS
@import "zerostatic/global/reset";
@import "zerostatic/global/type";
@import "zerostatic/global/content";
@import "zerostatic/global/syntax";
@import "zerostatic/global/syntax-dark";
@import "zerostatic/global/section";
@import "zerostatic/global/header";
@import "zerostatic/global/footer";
@import "zerostatic/global/bottom";
@import "zerostatic/global/logo";
@import "zerostatic/global/main-menu";
@import "zerostatic/global/main-menu-mobile";
@import "zerostatic/global/form";

@import "zerostatic/blocks/sections/grid";
@import "zerostatic/blocks/sections/title";
@import "zerostatic/blocks/sections/info";
@import "zerostatic/blocks/sections/partners";
@import "zerostatic/blocks/sections/heading";
@import "zerostatic/blocks/sections/hero";

@import "zerostatic/blocks/components/sidebar";
@import "zerostatic/blocks/components/social";
@import "zerostatic/blocks/components/subscribe";
@import "zerostatic/blocks/components/breadcrumbs";
@import "zerostatic/blocks/components/pagination";
@import "zerostatic/blocks/components/author";
@import "zerostatic/blocks/components/dark-mode";
@import "zerostatic/blocks/components/button";
@import "zerostatic/blocks/components/comments";

// CUSTOM
@import "content";


// Font Awesome
$fa-font-path: "../fonts/font-awesome/webfonts";
@import "libraries/font-awesome/fontawesome.scss";
@import "libraries/font-awesome/solid.scss";
@import "libraries/font-awesome/regular.scss";
@import "libraries/font-awesome/brands.scss";

*,
*::before,
*::after {
  box-sizing: border-box;
}

body {
  margin: 0;
  font-family: $font-family-base;
  font-size: 18px;
  color: var(--color-base-text);
  background: var(--color-base-bg);
  display: flex;
  min-height: 100vh;
  flex-direction: column;
}
.page {
  .wrapper {
    flex: 1;
  }
}

.container {
  padding-left: $grid-gutter-width;
  padding-right: $grid-gutter-width;
}

// Tweaks for dark mode
html[data-mode="dark"] {
}