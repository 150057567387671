#table-of-contents {
  background-color: var(--color-base-bg-3);
  padding: 5%;
  border-radius: 1rem;
  margin-bottom: 1.5rem;
}

#table-of-contents > ul {
  list-style-type: disc;
  list-style-position: inside;
  margin-left: 1rem;
}

/* Level 1 */
.toc-entry.toc-h1 {
  margin-left: 1rem;
}

/* Level 2 */
.toc-entry.toc-h2 {
  margin-left: 2rem;
  list-style-type: circle;
}

/* Level 3 */
.toc-entry.toc-h3 {
  margin-left: 3rem;
  list-style-type: square;
}

/* Add additional levels as needed, increasing margin-left each time */